<!-- 客户销售表 -->
<template>
  <div class="pagePadding">
    <Form inline class="formMarginBtm20">
      <!-- 客户名称 -->
      <FormItem>
        <span>客户名称：</span>
        <Select placeholder="请输入" v-model="supplierIndex" clearable filterable class="iviewIptWidth250 marginRight60">
          <Option v-for="(item, index) in customIdList" :value="index" :key="item.customer_id + item.customer_type">{{ item.customer_name }}</Option>
        </Select>
      </FormItem>
      <FormItem>
        <span>销售时间：</span>
        <DatePicker type="date" placeholder="请选择" class="iviewIptWidth250" @on-change="changeTime($event, 1)"></DatePicker>
        <span style="padding: 0 10px;">至</span>
        <DatePicker :options="options" @on-change="changeTime($event, 2)" v-model="queryFrom.sale_end_date" type="date" placeholder="请选择" class="iviewIptWidth250"></DatePicker>
      </FormItem>
      <!-- 产品编码  -->
      <FormItem class="marginLeft40">
        <span>产品编码：</span>
        <Input placeholder="请输入" class="iviewIptWidth250" v-model.trim="queryFrom.product_model_code" />
      </FormItem>
      <FormItem>
        <span>产品名称：</span>
        <Select class="iviewIptWidth250 marginRight60" v-model="queryFrom.product_id" @on-change="productChange" clearable filterable placeholder="请选择" ref="name_select">
          <Option v-for="(item, index) in productList" :value="item.product_id" :key="index" :label="item.product_name">{{ item.product_name }}</Option>
        </Select>
      </FormItem>
      <FormItem>
        <span>规格型号/SKU：</span>
        <Select class="iviewIptWidth250 marginRight60" multiple filterable clearable v-model="queryFrom.product_code_array" :max-tag-count="queryFrom.product_code_array.length == 1 ? 1 : 0">
          <Option v-for="(item, index) in modelList" :value="item.specification_id" :key="index" :label="item.model_name">
            <span>{{ item.model_name }}</span>
            <span style="float: right; color: #ccc; margin-right: 20px;">{{ item.item_number }}</span>
          </Option>
        </Select>
      </FormItem>
      <!-- 按钮 -->
      <FormItem class="po-create-number marginRight0" :label-width="10">
        <span class="pageBtn finger btnSure" @click="query()">查询</span>
        <span class="pageBtn finger btnSure marginLeft20">
          <span v-if="!Loagin" class="pageBtn finger btnSure" @click="Export">导出</span>
          <span v-if="Loagin" class="pageBtn finger btnSure">
            <Spin v-if="Loagin" style="display: inline-block; color: white;">
              <Icon type="ios-loading" class="demo-spin-icon-load"></Icon>
            </Spin>
          </span>
        </span>
      </FormItem>
    </Form>
    <div class="clearfix tabDiv">
      <Spin fix v-if="tabloading"></Spin>
      <Table @on-sort-change="sortHospital" :columns="listColumns" :total="total" :pages="pages" :data="listData" border show-summary class="table" :summary-method="handleSummary"></Table>
      <template v-if="listData.length != 0">
        <div class="marginTop20">
          <Page v-if="total <= 60" :total="total" :current="pages.page" @on-change="changePage" class="fl page" />
          <Page v-if="total > 60" show-elevator :total="total" :current="pages.page" @on-change="changePage" class="fl page" />
          <div class="fr totalFont page">共计{{ total }}条记录</div>
        </div>
      </template>
      <div style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important;" class="clearfix noData" v-else>
        <img style="margin-top: 100px;" src="../../assets/images/tableNoData.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'customerSalesTable',
  data() {
    return {
      Loagin: false,
      tabloading: false,
      supplierIndex: -1,
      // 表格数据
      listData: [],
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 80,
          key: 'index',
        },
        {
          title: '客户名称',
          key: 'customer_name',
          align: 'center',
          minWidth: 160,
        },
        {
          title: '销售品种',
          key: 'sale_species',
          align: 'center',
          width: 120,
        },
        {
          title: '销售总量',
          key: 'sale_count',
          align: 'center',
          width: 120,
        },
        {
          title: '销售金额',
          key: 'sale_amount',
          sortable: 'custom',
          align: 'center',
          minWidth: 150,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableBlakcFont',
                },
                '¥' + param.row.sale_amount
              ),
            ])
          },
        },
        {
          title: '销售不含税金额',
          key: 'sale_tax_amount',
          align: 'center',
          minWidth: 150,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableBlakcFont',
                },
                '¥' + param.row.sale_tax_amount
              ),
            ])
          },
        },
        // sale_tax_amount
        {
          title: '销售比重',
          key: 'sale_proportion',
          align: 'center',
          width: 120,
        },
        {
          title: '利润',
          key: 'sale_profits',
          sortable: 'custom',
          align: 'center',
          minWidth: 150,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableBlakcFont',
                },
                '¥' + (param.row.sale_profits ? param.row.sale_profits.toFixed(2) : 0)
              ),
            ])
          },
        },
        {
          title: '利润率',
          key: 'sale_profit_margin',
          align: 'center',
          width: 120,
        },
        {
          title: '操作',
          align: 'center',
          width: 150,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',
                    fontSize: '14px',
                    cursor: 'pointer',
                  },
                  on: {
                    click: () => {
                      this.details(param.row.customer_id, param.row.customer_type)
                    },
                  },
                },
                '详情'
              ),
            ])
          },
        },
      ],
      // 经手人下拉
      userList: [],
      // 仓库下拉
      warehouseList: [],
      // 产品下拉
      productList: [],

      thirdList: [],
      // 表单拉下选项
      // 客户名称下拉列表
      customIdList: [],
      customTypeList: [],
      // 查询参数
      queryFrom: {
        customer_id: '',
        customer_type: '',
        handled_by: '', // 经手人id
        warehouse_id: '',
        product_id: '',
        sale_begin_date: '',
        sale_end_date: '',
        product_code_array: [],
        product_model_code: '',
      },
      total: 1,
      pages: {
        page: 1,
        rows: 10,
      },
      options: {},
      totalMoney: 0,
      profitMoney: 0,
      saleTaxAmount: 0,
      productList: [],
      modelList: [],
    }
  },
  created() {
    // this.getcustomerSalesTable()
    this.getcustomerList()
    this.getpublicSpecialUser()
    this.getpermissionWareHouseList()
    this.getProductList()
    this.queryProductCodeSelect()
  },
  methods: {
    sortHospital(data) {
      this.queryFrom.sort_type = data.order
      this.queryFrom.sort_field = data.key
      this.getcustomerSalesTable()
    },
    // 产品名称改变
    productChange(e) {
      this.queryFrom.product_code_array = ''
      // if (!e) return
      this.modelList = []
      this.queryProductCodeSelect(e)
    },
    // 获取供应商下指定产品下的规格型号
    queryProductCodeSelect(id) {
      this.$http.get(this.$api.acceptanceCodeSelect, { product_id: id }, false).then(res => {
        this.modelList = res.data
      })
    },
    // 导出
    Export() {
      if (this.Loagin) {
        return
      }
      if (this.supplierIndex >= 0) {
        this.queryFrom.customer_id = this.customIdList[this.supplierIndex].customer_id
        this.queryFrom.customer_type = this.customIdList[this.supplierIndex].customer_type
      } else {
        this.queryFrom.customer_id = ''
        this.queryFrom.customer_type = ''
      }
      let data = {
        customer_id: this.queryFrom.customer_id,
        customer_type: this.queryFrom.customer_type,
        handled_by: this.queryFrom.handled_by, // 经手人id
        warehouse_id: this.queryFrom.warehouse_id,
        product_id: this.queryFrom.product_id,
        sale_begin_date: this.queryFrom.sale_begin_date,
        sale_end_date: this.queryFrom.sale_end_date,
        sort_type: this.queryFrom.sort_type,
        sort_field: this.queryFrom.sort_field,
      }
      this.Loagin = true
      this.$http.downFile(this.$api.getStatementSaleListLxport, data, false).then(res => {
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '客户销售表.xlsx'
        aLink.click()
        this.Loagin = false
      })
    },
    changePage(e) {
      this.pages.page = e
      this.getcustomerSalesTable()
    },
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        that.queryFrom.sale_begin_date = e
        that.queryFrom.sale_end_date = ''
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.queryFrom.sale_begin_date)
          },
        }
      } else {
        this.queryFrom.sale_end_date = e
      }
    },
    // 点击查询
    query() {
      this.pages.page = 1
      this.getcustomerSalesTable()
    },
    // 获取客户销售表
    getcustomerSalesTable() {
      this.tabloading = true
      this.totalMoney = 0
      this.profitMoney = 0
      this.saleTaxAmount = 0
      if (this.supplierIndex >= 0) {
        this.queryFrom.customer_id = this.customIdList[this.supplierIndex].customer_id
        this.queryFrom.customer_type = this.customIdList[this.supplierIndex].customer_type
      } else {
        this.queryFrom.customer_id = ''
        this.queryFrom.customer_type = ''
      }
      let data = {
        sort_type: this.queryFrom.sort_type,
        sort_field: this.queryFrom.sort_field,
        page: this.pages.page,
        rows: this.pages.rows,
        customer_id: this.queryFrom.customer_id,
        customer_type: this.queryFrom.customer_type,
        handled_by: this.queryFrom.handled_by, // 经手人id
        warehouse_id: this.queryFrom.warehouse_id,
        product_id: this.queryFrom.product_id,
        sale_begin_date: this.queryFrom.sale_begin_date,
        sale_end_date: this.queryFrom.sale_end_date,
        product_model_code: this.queryFrom.product_model_code,
        model_name: this.queryFrom.product_code_array ? this.queryFrom.product_code_array.join(',') : null,
      }
      // 产品名称
      if (this.queryFrom.product_id) {
        let obj = this.productList.find(item => {
          return item.product_id == this.queryFrom.product_id
        })
        data.product_name = obj.product_name
      }
      this.$http.get(this.$api.statementSaleList, data, false).then(res => {
        this.listData = res.data
        this.total = res.total
        for (let i = 0; i < this.listData.length; i++) {
          this.totalMoney += this.listData[i].sale_amount
          this.profitMoney += this.listData[i].sale_profits
          this.saleTaxAmount = (this.listData[i].sale_tax_amount ? this.listData[i].sale_tax_amount.replace(/,/g, '') * 1 : 0) + this.saleTaxAmount
        }
        this.tabloading = false
      })
    },

    // 获取客户下拉接口
    getcustomerList() {
      this.$http.get(this.$api.customerList, true).then(res => {
        this.customIdList = res.data
      })
    },

    // 经手人
    getpublicSpecialUser() {
      let data = {
        user_type: '1',
      }
      this.$http.get(this.$api.publicSpecialUser, data, true).then(res => {
        this.userList = res.data
      })
    },

    // 发货仓库
    getpermissionWareHouseList() {
      let data = {
        page: this.pages.page,
        rows: this.pages.rows,
      }
      this.$http.get(this.$api.permissionWareHouseList, data, true).then(res => {
        this.warehouseList = res.data
      })
    },

    // 产品下拉
    getProductList() {
      let data = {
        search_type: 3,
      }
      this.$http.get(this.$api.productShow, data, true).then(res => {
        this.productList = res.data
      })
    },
    handleSummary({ columns, data }) {
      const sums = {}
      //
      columns.forEach((column, index) => {
        const key = column.key
        if (index === 0) {
          sums[key] = {
            key,
            value: '合计',
          }
          return
        }
        // if (index === 2) {
        //   sums[key] = {
        //     key,
        //     value: this.total,
        //   }
        //   return
        // }
        if (index === 4) {
          sums[key] = {
            key,
            value: this.$utils.formatMoney(this.totalMoney, true),
          }
          return
        }
        if (index === 5) {
          sums[key] = {
            key,
            value: this.$utils.formatMoney(this.saleTaxAmount, true),
          }
          return
        }
        if (index === 6) {
          sums[key] = {
            key,
            value: '100.00%',
          }
          return
        }
        if (index === 7) {
          sums[key] = {
            key,
            value: this.$utils.formatMoney(this.profitMoney, true),
          }
          return
        }
        if (index === 8) {
          sums[key] = {
            key,
            value: '',
          }
          return
        }
        const values = data.map(item => Number(item[key]))
        if (!values.every(value => isNaN(value))) {
          const v = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[key] = {
            key,
            value: v,
          }
        } else {
          sums[key] = {
            key,
            value: '',
          }
        }
      })

      return sums
    },
    // 详情
    details(id, type) {
      this.$router.push({
        path: '/detailsCustomerSalesTable',
        query: {
          id: id,
          type: type,
          sale_begin_date: this.queryFrom.sale_begin_date,
          sale_end_date: this.queryFrom.sale_end_date,
        },
      })
    },
  },
  filters: {
    formatMoney(val, decimal, symbol) {
      if (!val || isNaN(val)) return '0'
      var num = parseFloat(val)
      num = String(num.toFixed(decimal || 0))
      var re = /(-?\d+)(\d{3})/
      while (re.test(num)) {
        num = num.replace(re, '$1,$2')
      }
      return symbol ? symbol + num : num
    },
  },
}
</script>

<style scoped lang="less">
.marginTop20 {
  margin-top: 20px;
}
.table {
  /deep/ .ivu-table-summary {
    border-top: 0;
  }
}
.marginRight60 {
  margin-right: 60px;
}
.pageTop {
  flex: 1;
  height: 100%;
}
</style>
